<template>
    <div class="barChart">
    </div>
</template>

<script>
import {getClassBorrow} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String,
        time: Array
    },
    data() {
        return {

        }
    },
    methods: {
        setChart() {
            // let color = ['#3EF3FF'];
            // let echartData = [
            //     {
            //         name: '02.18',
            //         value1: 100,
            //         value2: 233,
            //         value3: 253,
            //         value4: 260,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 138,
            //         value2: 233,
            //         value3: 193,
            //         value4: 270,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 350,
            //         value2: 200,
            //         value3: 293,
            //         value4: 240,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 350,
            //         value2: 200,
            //         value3: 293,
            //         value4: 240,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 350,
            //         value2: 200,
            //         value3: 293,
            //         value4: 240,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 350,
            //         value2: 200,
            //         value3: 293,
            //         value4: 240,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 350,
            //         value2: 200,
            //         value3: 293,
            //         value4: 240,
            //     },
            //     {
            //         name: '02.19',
            //         value1: 350,
            //         value2: 200,
            //         value3: 293,
            //         value4: 240,
            //     },
            // ];
            let value2 = []
            let value1 = this.data.map(item => item.num)
            for (let index in value1) {
                value2[index] = Math.max(...value1) / 20;
            }
            let xAxisData = this.data.map((v) => v.deptName);
            // let yAxisData1 = echartData.map((v) => v.value1);

            let option = {
                legend: {
                    show: false
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0}：{c0}',
                    // formatter: function (params) {
                    //     let html = '';
                    //     params.forEach((v) => {
                    //         console.log(v);
                    //         html += `<div style="color: #666;font-size: 12px;line-height: 24px">
                    //         <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                    //             color[v.componentIndex]
                    //         };"></span>
                    //         ${v.seriesName} ${v.name}月
                    //         <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 12px">${v.value}</span>
                    //         `;
                    //     });

                    //     return html;
                    // },

                },
                dataZoom: {
                    show: false,
                    type: "inside",     // inside: 表示用内测滑块
                    startValue: 0,     // 开始显示的数
                    endValue: 9,       // 结束显示的数
                },
                grid: {
                    top: 20,
                    left: 0,
                    bottom: 0,
                    right: 20,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        // boundaryGap: [0.2, 0.2],
                        axisLabel: {
                            // formatter: '{value}月',
                            textStyle: {
                                fontSize: 11,
                                fontFamily: 'Microsoft YaHei',
                                fontWeight: 400,
                                color: '#fff',
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(177,222,255,1)',
                                type: "solid"
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#fafafa',
                                fontSize: 12,
                                fontFamily: 'Microsoft YaHei',
                                fontWeight: 400,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(177,222,255,0.4)',
                                type:'dashed'
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '城区数据',
                        data: value1,
                        type: 'bar',
                        stack: '合并',
                        // barGap: "100%",
                        barWidth: '38%', //柱条的宽度，不设时自适应。
                        itemStyle: {
                            //定义柱子的样式
                            // color:params=>color[params.dataIndex],//定义每根柱子的不同颜色(不渐变)
                            //不同柱子颜色渐变
                            color: () => ({
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                    offset: 0,
                                    color: "rgba(62,243,255,0.6)", // 柱子最高度% 处的颜色
                                    },
                                    {
                                    offset: 1,
                                    color: 'rgba(62,243,255,0.1)', // X轴处的颜色
                                    },
                                ],
                                global: false, // 缺省为 false
                            }),
                            borderRadius: [8, 8, 0, 0], //柱子圆角[上右下左]，也可以统一值。
                        },
                        showBackground: true, //柱子是否带有背景，默认是没有的
                        backgroundStyle: {
                            //只有showBackground=true，设置backgroundStyle才会有效果
                            color: 'rgba(177, 222, 255, 0.20)', //
                            borderRadius: 8,
                        },
                    },
                    // {
                    //     type: 'bar',
                    //     data: value1,
                    //     stack: '合并',
                    //     barWidth: '38%',
                    //     itemStyle: {
                    //         //定义柱子的样式
                    //         // color:params=>color[params.dataIndex],//定义每根柱子的不同颜色(不渐变)
                    //         //不同柱子颜色渐变
                    //         color: () => ({
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                 offset: 0,
                    //                 color: "rgba(62,243,255,0.6)", // 柱子最高度% 处的颜色
                    //                 },
                    //                 {
                    //                 offset: 1,
                    //                 color: 'rgba(62,243,255,0.1)', // X轴处的颜色
                    //                 },
                    //             ],
                    //             global: false, // 缺省为 false
                    //         }),
                    //         borderRadius: [8, 8, 0, 0], //柱子圆角[上右下左]，也可以统一值。
                    //     },
                    //     label: {
                    //         normal: {
                    //         show: true,
                    //         formatter: '{c}',
                    //         position: 'top',
                    //         textStyle: {
                    //             color: '#7DB2FF',
                    //             fontSize: 16,
                    //             padding: 5,
                    //         },
                    //         },
                    //     },
                    //     },
                    {
                        type: 'bar',
                        stack: '合并',
                        barGap: "100%",
                        data: value2,
                        barWidth: '38%',
                        itemStyle: {
                            normal: {
                            color: 'rgba(255, 205, 62, 1)',
                            },
                        },
                    },
                ],
            };
            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()

            myChart.setOption(option);
            // let obj = {
            //     type: 'highlight',
            //     name: []
            // };
            // for (let i = 0; i < this.data.data.length - 1; i++) {
            //     obj.name.push(this.data.data[i].name)
            // }
            // myChart.dispatchAction(obj);
            // myChart.on('mouseout', function () {
            //     myChart.dispatchAction(obj);
            // });
        },
        initData() {
            if (!this.syncId) return
            debugger
            getClassBorrow({
                syncId:this.syncId,
                startDate: this.time[0],
                endDate: this.time[1]
            }).then(res => {
                console.log(res)
                debugger
            })
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.setChart();
        // }, 100);
        // let myData = {}
        // myData = {
        //     data: [
        //         '细口瓶-哈哈哈哈',
        //         '烧杯',
        //         '条形盒',
        //         '测力盒',
        //         '电子天平-嘿嘿嘿',
        //         '广口瓶-哟嘿',
        //         '金属槽码mmmmm',
        //         '足球门',
        //         '试管',
        //         '钟表模型',
        //         '试管架',
        //     ],
        //     value: [1073, 963, 807, 782, 702, 679, 516, 498, 408, 346, 188],
        //     value2: [],
        // };
        // for (let index in myData.value) {
        //     myData.value2[index] = Math.max(...myData.value) / 100;
        // }
        this.initData()
    },
    watch: {
        time: {
            handler() {
                this.initData()
            },
                deep: true
            }
        }
    }
</script>

<style lang="less" scoped>
.barChart {
    width: 100%;
    height: 100%;
}
</style>